export const environment = {
    production: true,
    env: 'staging',
    apiUrl: 'https://api.staging.rgrc.h6.run',
    azureRedirectUri: 'https://staging.rgrc.h6.run/login',
    clientId: '0c108372-9ae4-48a8-84d9-2cd678ee8b7f',
    authority: 'https://login.microsoftonline.com/60d9256f-e5a7-4b70-929b-6671aaaab3ed',
    whitelistedDomains: ['api.staging.rgrc.h6.run'],
    blacklistedRoutes: [],
    recaptcha_site_key: '',
};
